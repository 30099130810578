import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';
import Email from '../components/Email';
import Split from '../components/Split';

const MarliRussellPage = ({ data }) => {
  return (
    <Layout>
      <Section heading="Marli Russell">
        <Split reverse>
          <Img fluid={data.image.childImageSharp.fluid} />
          <div>
            <p>
              This year marked the 4th release of our Marli Russell label with the
              release of the 2017 RP1 blend (Marsanne, Roussane and Clairette) and
              the 2017 RP2 blend (Grenache, Mourvedre, Shiraz, Cinault).
            </p>
            <p>
              It’s now over 10 years since we started to experiment with plantings
              of these 7 varieties traditionally grown in the Rhone Valley with our
              vision to explore the potential of our unique site under changing
              environmental conditions.
            </p>
            <p>
              The wines have continued to evolve across the 4 vintages as the vines
              have aged and new varieties have been added to the blends.
            </p>
            <p>
              Thank you to those who have supported this new label over the past
              few years. If you haven’t already tried the wines, we’d encourage you
              to attend next year’s launch event of the 2018 wines.
            </p>
            <p>
              Stay tuned for information early in 2020. Or email <Email /> with any
              ordering questions in the meantime.
            </p>
          </div>
        </Split>
      </Section>
      <Pagination
        forwardLink="/release"
        backLink="/visiting"
      />
    </Layout>
  );
};

MarliRussellPage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    image: file(relativePath: { eq: "marli-russell.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default MarliRussellPage;
