import React from 'react';

import styles from './Email.module.css';

const ADMIN_EMAIL_ADDRESS = 'admin@mountmary.com.au';

const Email = () => (
  <a
    href={`mailto:${ADMIN_EMAIL_ADDRESS}`}
    className={styles.link}
  >
    {ADMIN_EMAIL_ADDRESS}
  </a>
);

export default Email;
