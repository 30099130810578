import React from 'react';
import PropTypes from 'prop-types';

import styles from './Split.module.css';

const Split = ({ children, reverse }) => (
  <div
    className={`${styles.wrapper} ${reverse ? styles.reverse : styles.standard}`}
  >{children}</div>
);

Split.propTypes = {
  children: PropTypes.node,
  reverse: PropTypes.bool,
};

Split.defaultProps = {
  reverse: false,
};

export default Split;
